import React from 'react';
import PropTypes from 'prop-types';
import style from './style.css';
import Table from 'cccisd-graphql-table';
import CycleStatus from './CycleStatus';
// import IconEdit from 'cccisd-icons/pencil2';
import Modal from 'cccisd-modal';
import IconPrint from 'cccisd-icons/printer2';
import IconMessage from 'cccisd-icons/envelop3';
import IconContact from 'cccisd-icons/info';
import IconWarn from 'cccisd-icons/warning';
import IconLink from 'cccisd-icons/link';
import IconEdit from 'cccisd-icons/pencil2';
import IconPlus from 'cccisd-icons/plus2';
import IconView from 'cccisd-icons/eye';
import Button from 'cccisd-click-button';
import _get from 'lodash/get';
import classnames from 'classnames';
import respondentsQuery from 'js/graphql/deploymentRespondents.graphql';
import { connect } from 'react-redux';
import { setCycle } from 'js/reducers/data.js';
import deploymentQuery from 'js/graphql/deploymentsByAssignment.graphql';
import { taskMasterClient } from 'cccisd-apollo';
import moment from 'moment';
import { BulkUploader } from 'cccisd-laravel-nexus';
import Tooltip from 'cccisd-tooltip';
import Toggle from 'cccisd-toggle';
import ContactCard from 'js/components/YouthRostering/ContactCard';
import StatusIcon from './StatusIcon';
import axios from 'cccisd-axios';
import notification from 'cccisd-notification';
import { DeploymentPlayer, Messaging } from 'cccisd-laravel-assignment';
import SetEmail from './SetEmail';
import LoggedPawnMessages from './LoggedPawnMessages';
import PrintView from './PrintView';
import Loader from 'cccisd-loader';
import SendMessageNow from './SendMessageNow';
import copy from 'copy-to-clipboard';

const Boilerplate = window.cccisd.boilerplate;

class TabView extends React.Component {
    static propTypes = {
        cycle: PropTypes.string,
        assignmentId: PropTypes.number,
        showClosed: PropTypes.bool,
        match: PropTypes.object,
        // from redux
        cycles: PropTypes.object,
        site: PropTypes.number,
        setCycle: PropTypes.func,
    };

    static contextTypes = {
        router: PropTypes.object,
    };

    constructor(props, context) {
        super(props, context);
        this.modal = React.createRef();
    }

    state = {
        cycles: [],
        selectedCycle: null,
        target: 0,
        progress: 0,
        responseRate: 0,
        responseRateCount: 0,
        bulkDef: null,
        loading: true,
    };

    componentDidMount = async () => {
        if (this.props.site) {
            this.loadData();
        }
    };

    componentDidUpdate = async (prevProps, prevState) => {
        if (this.needsUpdate(prevProps, prevState)) {
            this.loadData();
            if (this.state.selectedCycle) {
                this.reloadTableData();
            }
        }

        // console.log({
        //     cycle: this.state.selectedCycle,
        //     current: this.state.bulkDef,
        //     previous: prevState.bulkDef,
        // });
        if (prevState.selectedCycle !== this.state.selectedCycle) {
            this.calculateTargetProgress();
        }
    };

    needsUpdate = (prevProps, prevState) => {
        return (
            prevProps.assignmentId !== this.props.assignmentId ||
            prevProps.site !== this.props.site ||
            prevState.cycles.length !== this.state.cycles.length ||
            prevProps.showClosed !== this.props.showClosed
        );
    };

    reloadTableData = async () => {
        if (this.table) {
            this.table.loadData();
            this.calculateTargetProgress();
        }
    };

    loadData = async () => {
        this.setState({ loading: true });
        await this.getCycles();
        this.getSelectedCycle();
        this.calculateTargetProgress();
    };

    getBulkUploadDef = async () => {
        const { deploymentId } = this.state?.selectedCycle;
        if (!deploymentId) {
            return;
        }
        const response = await axios.get(Boilerplate.route('api.nexus.bulkupload.deploymentRespondentMetricsDef'), {
            params: { deploymentId },
        });
        const responseBulkDef = _get(response, 'data.result.original.data');
        if (responseBulkDef) {
            this.setState({ bulkDef: responseBulkDef });
            return responseBulkDef;
        }
        console.error('Could not retrieve bulk upload definition');

        return {};
    };

    calculateTargetProgress = () => {
        if (this.state.selectedCycle) {
            const {
                respondentCount,
                numCompleted,
                // optedOut,
                notConsented,
                responseRateCount,
                invalid,
            } = this.state.selectedCycle;

            const responseRate = numCompleted - invalid - notConsented;
            this.setState({
                target: respondentCount,
                responseRateCount,
                progress: numCompleted,
                responseRate,
            });
        }
    };

    getSelectedCycle = () => {
        if (this.state.cycles.length > 0) {
            const savedCycle = this.props.cycles[this.props.cycle];
            let selectedCycle = this.state.cycles[0];
            // is there something in redux for this tab?
            if (savedCycle) {
                selectedCycle = this.state.cycles.find(option => option.value === savedCycle);
            }
            this.setState({ selectedCycle });
            window.history.replaceState(
                { page: 1 },
                '',
                `${window.location.pathname}?deploymentId=${selectedCycle.deploymentId}`
            );
        } else {
            this.setState({ selectedCycle: null });
        }
    };

    setSelectedCycle = async deploymentId => {
        const payload = { name: this.props.cycle, id: deploymentId };
        this.props.setCycle(payload);
        const selectedCycle = this.state.cycles.find(option => option.value === deploymentId);
        await this.setState({
            selectedCycle,
        });
        window.history.replaceState(
            { page: 1 },
            '',
            `${window.location.pathname}?deploymentId=${selectedCycle.deploymentId}`
        );

        this.reloadTableData();
    };

    getCycles = async () => {
        const { assignmentId, site } = this.props;
        let filter = {
            in: {
                field: 'siteId',
                int: [site],
            },
        };
        const now = moment().utc().format('YYYY-MM-DD HH:mm:ss');
        if (this.props.showClosed === false) {
            filter = {
                AND: [
                    {
                        gte: {
                            field: 'closesAt',
                            string: now,
                        },
                    },
                    {
                        in: {
                            field: 'siteId',
                            int: [site],
                        },
                    },
                ],
            };
        }
        const response = await taskMasterClient.query({
            query: deploymentQuery,
            variables: { deployFilter: filter, assignmentId },
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
        });
        const cycles = response.data.flows.assignment.deploymentList.map(cycle => ({
            label: cycle.label,
            settings: cycle.settings,
            value: cycle.deploymentId,
            sampleSize: cycle.settings.sampleSize,
            numResponses: null,
            startDate: moment(moment.utc(cycle.opensAt).toDate()).format('MM/DD/YYYY hh:mm A'),
            endDate: moment(moment.utc(cycle.closesAt).toDate()).format('MM/DD/YYYY hh:mm A'),
            deploymentId: cycle.deploymentId,
            respondentCount: cycle.respondentCount,
            numCompleted: cycle.numCompleted,
            optedOut: cycle.optedOut,
            notConsented: cycle.notConsented,
            trackConsent: cycle.settings.trackConsent,
            trackIncentives: cycle.settings.trackIncentives,
            consentWfiForms: cycle.settings.consentWfiForms,
            incentiveWfiForms: cycle.settings.incentiveWfiForms,
            // responseRateCount: cycle.responseRateDen,
            responseRateCount: cycle.respondentCount - cycle.invalid,
            invalid: cycle.invalid,
            assignment: cycle.assignment,
            hash: cycle.hash,
        }));
        // console.log({ cycles });
        this.setState({ cycles, loading: false });
    };

    toggle = async (field, pawnId, currentVal) => {
        const { deploymentId } = this.state.selectedCycle;
        const response = await axios.put(Boilerplate.route('update.respondent.info'), {
            pawnId,
            deploymentId,
            [field]: !currentVal,
        });
        if (response.data.errors) {
            let errors = response.data.errors;
            notification({ message: errors, type: 'danger' });
            return Promise.reject(errors);
        }
        this.reloadTableData();
    };

    closeModal = () => {
        this.modal.current.close();
    };

    copyText = row => {
        copy(Boilerplate.url(`/d/${row['deployment.hash']}/${row['pawn.pawn.respondentHash']}`));

        notification({
            message: 'Survey URL copied successfully!',
            type: 'success',
            duration: 8000,
        });
    };

    getColumns = () => {
        const { cycle } = this.props;
        const { selectedCycle } = this.state;
        const cycleLabels = {
            careCoord: {
                label: 'Care Coordinator Contact',
                name: 'careCoordinatorFullName',
                email: 'careCoordinatorEmail',
                phone: 'careCoordinatorPhone',
            },
            caregiver: {
                label: 'Caregiver Contact',
                name: 'pawn.fields.caregiverName',
                language: 'pawn.fields.caregiverLanguage',
                specifyLang: 'pawn.fields.caregiverSpecifyLanguage',
                email: 'pawn.fields.caregiverEmail',
                phone: 'pawn.fields.caregiverPhone',
            },
            teamMember: {
                label: 'Team Member Contact',
                name: 'pawn.fields.teamMemberName',
                language: 'pawn.fields.teamMemberLanguage',
                specifyLang: 'pawn.fields.teamMemberSpecifyLanguage',
                email: 'pawn.fields.teamMemberEmail',
                phone: 'pawn.fields.teamMemberPhone',
            },
            youth: {
                label: 'Youth Contact',
                name: 'youthFullName',
                language: 'pawn.fields.language',
                specifyLang: 'pawn.fields.specifyLanguage',
                email: 'pawn.fields.youthEmail',
                phone: 'pawn.fields.youthPhone',
            },
            tom: {
                label: 'Care Coordinator Contact',
                name: 'careCoordinatorFullName',
                email: 'careCoordinatorEmail',
                phone: 'careCoordinatorPhone',
            },
            dart: {
                label: 'Care Coordinator Contact',
                name: 'careCoordinatorFullName',
                email: 'careCoordinatorEmail',
                phone: 'careCoordinatorPhone',
            },
        };

        const now = moment();

        let columns = [
            {
                name: 'pawn.fields.wrapId',
                label: 'WrapID',
                sort: true,
                filter: true,
            },
            {
                name: 'pawn.fields.firstName',
                label: 'First Name',
                hideInTable: true,
            },
            {
                name: 'pawn.fields.lastName',
                label: 'Last Name',
                hideInTable: true,
            },
            {
                name: 'pawn.fields.language',
                label: 'Language',
                hideInTable: true,
            },
            {
                name: 'pawn.fields.youthPhone',
                label: 'Youth Phone',
                hideInTable: true,
            },
            {
                name: 'pawn.fields.youthEmail',
                label: 'Youth Email',
                hideInTable: true,
            },
            {
                name: 'youthFullName',
                label: 'Youth',
                sort: true,
                filter: true,
                hideInCsv: true,
            },
            {
                handle: 'pawn.assignmentProgress.evalCycleStatusCsv',
                name: 'assignmentProgress.evalCycleStatus',
                label: 'Cycle Status',
                hideInTable: true,
            },
            {
                handle: 'pawn.assignmentProgress.devTags.OptOutBoolean',
                name: 'assignmentProgress.devTags.OptOutBoolean',
                label: 'Administered',
                hideInTable: true,
            },
            {
                handle: 'pawn.assignmentProgress.devTags.OptOut',
                name: 'assignmentProgress.devTags.OptOut',
                label: 'Opt Out Reason',
                hideInTable: true,
            },
            {
                name: 'assignmentProgress.evalCycleStatus',
                label: 'Status',
                hideInCsv: true,
                sort: true,
                filter: true,
                filterSettings: {
                    type: 'selectbox',
                    options: [
                        { label: 'Not Started', value: 'Not Started' },
                        { label: 'In Progress', value: 'In Progress' },
                        { label: 'Completed', value: 'Complete' },
                    ],
                },
                render: ({ value, row }) => {
                    const status = getStatus(row);
                    let reason;
                    if (status === 'optout') {
                        reason = row['assignmentProgress.devTags.OptOut'];
                    }
                    return <StatusIcon tooltip={reason} status={status} />;
                },
            },
            {
                name: 'careCoordinatorFullName',
                label: cycleLabels[this.props.cycle].label,
                sort: true,
                filter: true,
                hideInCsv: true,
                render: ({ value, row }) => {
                    if (row['pawn.pawn.pawnHash'] === null) {
                        return '';
                    }
                    if (row['pawn.fields.hasCareCoord'] === false) {
                        return 'No Care Coordinator';
                    }
                    let contactFields = [
                        {
                            label: 'Name',
                            value: row[cycleLabels[this.props.cycle].name],
                        },
                        {
                            label: 'Preferred Language',
                            value:
                                row[cycleLabels[this.props.cycle].language] === 'other'
                                    ? row[cycleLabels[this.props.cycle].specifyLang]
                                    : getLang(row[cycleLabels[this.props.cycle].language]),
                        },
                        {
                            label: 'Phone Number',
                            value: row[cycleLabels[this.props.cycle].phone],
                        },
                        {
                            label: 'Email',
                            value: row[cycleLabels[this.props.cycle].email] ? (
                                row[cycleLabels[this.props.cycle].email]
                            ) : (
                                <Modal
                                    ref={this.modal}
                                    size="small"
                                    trigger={
                                        <button type="button" className="btn btn-warning">
                                            <IconWarn spaceRight /> Add Email
                                        </button>
                                    }
                                    title={`Add ${cycleLabels[this.props.cycle].label} Email`}
                                >
                                    <SetEmail
                                        pawnId={row.pawnId}
                                        type={this.props.cycle}
                                        pawnHash={row['pawn.pawn.pawnHash']}
                                        closeParentModal={this.closeModal}
                                        reloadTable={this.reloadTableData}
                                    />
                                </Modal>
                            ),
                        },
                    ];
                    if (!['careCoord', 'teamMember'].includes(this.props.cycle)) {
                        contactFields.push({
                            label: 'Address',
                            value: getAddress(row, this.props.cycle),
                        });
                    }
                    return (
                        <div className={style.contact}>
                            <div>{row[cycleLabels[this.props.cycle].name]}</div>

                            <Tooltip
                                title={row[cycleLabels[this.props.cycle].email] ? 'View Contact' : 'Fix Missing Email'}
                            >
                                <Modal
                                    title={cycleLabels[this.props.cycle].label}
                                    trigger={
                                        <div style={{ fontSize: '1em' }}>
                                            {row[cycleLabels[this.props.cycle].email] ? (
                                                <IconContact />
                                            ) : (
                                                <div className="text-danger">
                                                    <IconWarn />
                                                </div>
                                            )}
                                        </div>
                                    }
                                >
                                    <ContactCard contact={contactFields} />
                                </Modal>
                            </Tooltip>
                        </div>
                    );
                },
            },
            {
                name: 'contactFullName',
                label: cycleLabels[this.props.cycle].label,
                hideInTable: true,
                render: ({ value, row }) => {
                    return row[cycleLabels[this.props.cycle].name];
                },
            },
            {
                name: 'contactEmail',
                label: 'Contact Email',
                hideInTable: true,
                render: ({ value, row }) => {
                    return row[cycleLabels[this.props.cycle].email];
                },
            },

            {
                name: 'actions',
                label: 'Actions',
                hideInCsv: true,
                render: ({ value, row, loadData }) => {
                    if (row['pawn.pawn.pawnHash'] === null) {
                        return 'Youth has been deleted';
                    }
                    const completed = row['assignmentProgress.completedDate'];
                    const btnColor = completed ? 'btn-warning' : 'btn-info';
                    return (
                        <div className={style.flex}>
                            {now.isAfter(moment(this.state.selectedCycle.startDate)) && (
                                <Modal
                                    afterClose={() => {
                                        this.reloadTableData();
                                        this.loadData();
                                    }}
                                    size="large"
                                    trigger={
                                        <Tooltip title={completed ? 'Update Data' : 'Enter Data'}>
                                            <button
                                                type="button"
                                                className={classnames(
                                                    'btn',
                                                    btnColor,
                                                    style.btn,
                                                    style.moveOver,
                                                    style.marginTop
                                                )}
                                            >
                                                {completed ? <IconEdit /> : <IconPlus />}
                                            </button>
                                        </Tooltip>
                                    }
                                    title={completed ? 'Update Data' : 'Enter Data'}
                                >
                                    <div>
                                        <div className={style.fixContainer}>
                                            <DeploymentPlayer
                                                key="true"
                                                deploymentId={this.state.selectedCycle.deploymentId}
                                                pawnId={row.pawnId}
                                                pawnHash={row['pawn.pawn.pawnHash']}
                                                onComplete={this.onComplete}
                                                flowProps={{
                                                    hidePlayButton: true,
                                                    isPrintMode: false,
                                                    variables: getFlowPropsVars(
                                                        row,
                                                        this.state.selectedCycle.settings.site
                                                    ),
                                                }}
                                            />
                                        </div>
                                    </div>
                                </Modal>
                            )}
                            <Modal
                                size="large"
                                trigger={
                                    <Tooltip title="Printable Survey">
                                        <button
                                            type="button"
                                            className={classnames(
                                                'btn',
                                                'btn-primary',
                                                style.btn,
                                                style.moveOver,
                                                style.marginTop
                                            )}
                                        >
                                            <IconPrint />
                                        </button>
                                    </Tooltip>
                                }
                                title="Print Cover Sheet"
                            >
                                <PrintView
                                    data={row}
                                    tool={this.state.selectedCycle && this.state.selectedCycle.label.split(':')[0]}
                                    assignmentId={this.props.assignmentId}
                                    endDate={this.state.selectedCycle.endDate}
                                />
                            </Modal>
                            <Modal
                                size="large"
                                trigger={
                                    <Tooltip title="Copy Survey URL">
                                        <button
                                            type="button"
                                            className={classnames(
                                                'btn',
                                                'btn-warning',
                                                style.btn,
                                                style.moveOver,
                                                style.marginTop
                                            )}
                                        >
                                            <IconLink />
                                        </button>
                                    </Tooltip>
                                }
                                title="Copy Survey URL"
                            >
                                <div>
                                    <div className={style.flexCenter}>
                                        {Boilerplate.url(
                                            `/d/${row['deployment.hash']}/${row['pawn.pawn.respondentHash']}`
                                        )}
                                    </div>

                                    <br />
                                    <div className={style.flexCenter}>
                                        <button
                                            type="button"
                                            onClick={() => this.copyText(row)}
                                            className={classnames('btn', 'btn-warning')}
                                        >
                                            <span>Copy URL</span>
                                        </button>
                                    </div>
                                </div>
                            </Modal>
                            {completed && (
                                <Modal
                                    afterClose={() => {
                                        this.reloadTableData();
                                        this.loadData();
                                    }}
                                    size="large"
                                    trigger={
                                        <Tooltip title="View Data">
                                            <button
                                                type="button"
                                                className={classnames(
                                                    'btn',
                                                    'btn-info',
                                                    style.btn,
                                                    style.moveOver,
                                                    style.marginTop
                                                )}
                                            >
                                                <IconView />
                                            </button>
                                        </Tooltip>
                                    }
                                    title="View Data"
                                >
                                    <div>
                                        <div className={style.fixContainer}>
                                            <DeploymentPlayer
                                                key="true"
                                                deploymentId={this.state.selectedCycle.deploymentId}
                                                pawnId={row.pawnId}
                                                pawnHash={row['pawn.pawn.pawnHash']}
                                                onComplete={this.onComplete}
                                                flowProps={{
                                                    hidePlayButton: true,
                                                    isPrintMode: true,
                                                    variables: getFlowPropsVars(
                                                        row,
                                                        this.state.selectedCycle.settings.site
                                                    ),
                                                }}
                                            />
                                        </div>
                                    </div>
                                </Modal>
                            )}
                        </div>
                    );
                },
            },
            {
                name: 'messageCount',
                label: 'Messages',
                sort: true,
                filter: true,
                className: 'text-center',
                hideInCsv: true,
                render: ({ value, row }) => (
                    <LoggedPawnMessages deploymentId={this.state.selectedCycle.deploymentId} row={row} />
                ),
            },
            {
                handle: 'pawn.messageCountCsv',
                name: 'messageCount',
                label: 'Messages',
                hideInTable: true,
            },
            {
                name: 'additionalInfo.consent',
                label: 'Consent Collected',
                sort: true,
                hideInCsv: true,
                render: ({ value, row }) => {
                    const val = row['additionalInfo.consent'];
                    return (
                        <Toggle value={val && val} onChange={() => this.toggle('consent', row.pawnId, val && val)} />
                    );
                },
            },
            {
                name: 'additionalInfo.incentive',
                label: 'Incentive Disbursed',
                sort: true,
                hideInCsv: true,
                render: ({ value, row }) => {
                    const val = row['additionalInfo.incentive'];
                    return (
                        <Toggle value={val && val} onChange={() => this.toggle('incentive', row.pawnId, val && val)} />
                    );
                },
            },
            {
                handle: 'additionalInfo.consentCsv',
                name: 'additionalInfo.consent',
                label: 'Consent Collected',
                hideInTable: true,
            },
            {
                handle: 'additionalInfo.incentiveCsv',
                name: 'additionalInfo.incentive',
                label: 'Incentive Disbursed',
                hideInTable: true,
            },
            {
                name: 'assignmentProgress.startedDate',
                label: 'Date Started',
                sort: true,
                filter: true,
                filterSettings: { type: 'date' },
                render: ({ value, row }) => formatDateCell(value),
            },
            {
                name: 'assignmentProgress.completedDate',
                label: 'Date Completed',
                sort: true,
                filter: true,
                filterSettings: { type: 'date' },
                render: ({ value, row }) => formatDateCell(value),
            },
        ];
        const selectedCycleType = selectedCycle.label.split(':')[0];

        return columns.filter(col => {
            if (col.name === 'messageCount' && (cycle === 'dart' || cycle === 'tom' || this.cycleIsImport())) {
                return false;
            }
            if (col.name === 'additionalInfo.consent') {
                if (
                    selectedCycle.consentWfiForms &&
                    selectedCycle.consentWfiForms.find(form => form.label === selectedCycleType)
                ) {
                    return true;
                }
                return cycle === 'tom' && !this.cycleIsImport() && selectedCycle.trackConsent;
            }
            if (col.name === 'additionalInfo.incentive') {
                if (
                    selectedCycle.incentiveWfiForms &&
                    selectedCycle.incentiveWfiForms.find(form => form.label === selectedCycleType)
                ) {
                    return true;
                }
                // TOM and DART do not have incentive tracking
                return false;
            }
            if (col.name === 'actions' && this.cycleIsImport()) {
                return false;
            }
            if (col.name === 'assignmentProgress.startedDate' && this.cycleIsImport()) {
                return false;
            }

            return true;
        });
    };

    cycleIsImport = () => {
        return this.state.selectedCycle.settings.cycleType === 'import';
    };

    render() {
        const projectId = 2;
        if (this.state.loading) {
            return <Loader loading />;
        }
        return (
            <div>
                {this.state.cycles.length > 0 && (
                    <CycleStatus
                        siteId={this.props.site}
                        assignmentId={this.props.assignmentId}
                        forCycle={this.props.cycle}
                        cycles={this.state.cycles}
                        selectedCycle={this.state.selectedCycle}
                        setSelectedCycle={this.setSelectedCycle}
                        target={this.state.target}
                        progress={this.state.progress}
                        responseRate={this.state.responseRate}
                        responseRateCount={this.state.responseRateCount}
                        hideResponseRate={false}
                    />
                )}

                {!this.state.loading && this.state.selectedCycle && (
                    <div className={style.marginTop}>
                        <Table
                            ref={element => {
                                this.table = element;
                            }}
                            rowKey="pawnId"
                            columns={this.getColumns()}
                            graphqlVariables={{
                                deploymentId: this.state.selectedCycle && this.state.selectedCycle.deploymentId,
                                assignmentId: this.props.assignmentId,
                            }}
                            query={respondentsQuery}
                            showRowActions={false}
                            rowActions={
                                this.cycleIsImport()
                                    ? false
                                    : [
                                          {
                                              name: 'sendNow',
                                              title: 'Send Message',
                                              action: async ({ selectedRows, loadData }) => {
                                                  // hidden action -- use the Send Messages Now button
                                                  notification('Something is done');
                                              },
                                          },
                                      ]
                            }
                            render={({ renderDefault, selectedRows }) => {
                                return (
                                    <div>
                                        {this.cycleIsImport() ? (
                                            <div className={style.flexEnd}>
                                                {this.state.bulkLoading ? (
                                                    <Button
                                                        title="Bulk Add Respondent Metrics"
                                                        className="btn btn-primary"
                                                        isLoading
                                                    />
                                                ) : (
                                                    <BulkUploader
                                                        handle="deploymentRespondentMetrics"
                                                        afterClose={() => {
                                                            this.reloadTableData();
                                                        }}
                                                        trigger={
                                                            <button type="button" className="btn btn-primary">
                                                                Bulk Add Youth Metrics
                                                            </button>
                                                        }
                                                        // definition={this.state.bulkDef}
                                                        loadDefinition={this.getBulkUploadDef}
                                                        deploymentId={this.state.selectedCycle.deploymentId}
                                                        modalTitle="Bulk Add Youth Metrics"
                                                    />
                                                )}
                                            </div>
                                        ) : (
                                            !['tom', 'dart'].includes(this.props.cycle) && (
                                                <div className={style.flexEnd}>
                                                    <Modal
                                                        trigger={
                                                            <button
                                                                type="button"
                                                                className={classnames(
                                                                    'btn',
                                                                    'btn-info',
                                                                    style.moveOver
                                                                )}
                                                            >
                                                                <IconMessage spaceright /> Send Messages Now
                                                            </button>
                                                        }
                                                        title="Send Messages Now"
                                                    >
                                                        <SendMessageNow
                                                            rtComponent={this.table ? this.table : null}
                                                            match={this.props.match}
                                                            deployment={this.state.selectedCycle}
                                                            hideMessageReplacementNotes
                                                        />
                                                    </Modal>
                                                    <Modal
                                                        trigger={
                                                            <button type="button" className="btn btn-info">
                                                                <IconMessage spaceRight />
                                                                Message Center
                                                            </button>
                                                        }
                                                        title=""
                                                        size="large"
                                                        render={() => (
                                                            <div>
                                                                <div className={style.header}>
                                                                    {this.state.selectedCycle.label}
                                                                </div>

                                                                <Messaging
                                                                    backLinkText=" "
                                                                    backLinkUrl="/dataManagement"
                                                                    deploymentId={this.state.selectedCycle.deploymentId}
                                                                    deployment={this.state.selectedCycle}
                                                                    deploymentIds={this.state.cycles.map(
                                                                        c => c.deploymentId
                                                                    )}
                                                                    projectId={projectId}
                                                                    hideMessageReplacementNotes
                                                                />
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            )
                                        )}
                                        <br />
                                        {renderDefault()}
                                    </div>
                                );
                            }}
                        />
                    </div>
                )}
                {!this.state.loading && !this.state.cycles.length > 0 && (
                    <div className={style.notice}>
                        <div className={style.text}>
                            {getNotice(this.state.loading, this.state.cycles.length, this.props.showClosed)}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export const getStatus = row => {
    if (
        row['assignmentProgress.devTags.OptOutBoolean'] === '0' ||
        row['assignmentProgress.devTags.OptOutBoolean'] === 'No'
    ) {
        return 'optout';
    }
    if (row['assignmentProgress.devTags.Consent'] === 'No' || row['assignmentProgress.devTags.Consent'] === '0') {
        return 'noconsent';
    }
    if (row['assignmentProgress.startedDate'] && !row['assignmentProgress.completedDate']) {
        return 'inprogress';
    }
    if (row['assignmentProgress.completedDate']) {
        return 'completed';
    }
    if (!row['assignmentProgress.startedDate']) {
        return 'notstarted';
    }
};

export const getDays = date => {
    // use this helper instead of moment.js to reduce memory
    return date.substring(0, 4) * 365 + date.substring(5, 7) * 30 + date.substring(8, 10) * 1;
};

export const getAddress = (row, type) => {
    return (
        <div>
            {row[`pawn.fields.${type}Address`] && (
                <div>
                    {row[`pawn.fields.${type}Address`]} <br />
                </div>
            )}
            {row[`pawn.fields.${type}City`] && row[`pawn.fields.${type}State`] && (
                <div>
                    {row[`pawn.fields.${type}State`]}, {row[`pawn.fields.${type}State`]}
                </div>
            )}
            {row[`pawn.fields.${type}ZipCode`]}
        </div>
    );
};

export const getFlowPropsVars = (row, siteName) => {
    // determine age of youth
    const now = moment();
    const nowStr = now.format('YYYY-MM-DD');
    const daysOfNow = getDays(nowStr);

    const dateOfBirth = row['pawn.fields.dateOfBirth'];
    const ageAtEnrollment = row['pawn.fields.ageAtEnrollment'];
    const startDate = row['pawn.fields.startDate'];
    let youthAge = 'Unknown';

    // determine age if DOB availible
    if (dateOfBirth) {
        youthAge = Math.floor((daysOfNow - getDays(dateOfBirth)) / 365);
    }

    // infer age based on ageAtEnrollment
    if (!dateOfBirth && ageAtEnrollment) {
        youthAge = parseInt(ageAtEnrollment, 10) + Math.floor((daysOfNow - getDays(startDate)) / 365);
    }

    return {
        siteName,
        wrapId: row['pawn.fields.wrapId'],
        youthAge: youthAge ? youthAge : 'unknown',
        firstName: row['pawn.fields.firstName'] !== null ? row['pawn.fields.firstName'] : ' ',
        lastName: row['pawn.fields.lastName'] !== null ? row['pawn.fields.lastName'] : ' ',
        careCoordinator: row.careCoordinatorNameEmail,
        showCareCoordinator: true,
    };
};

export const getNotice = (loading, cycleLen, showClosed) => {
    if (loading) {
        return 'Loading...';
    }
    if (!cycleLen > 0) {
        if (showClosed) {
            return 'No cycles. Create cycles from the Evaluation Cycles tab';
        }
        return 'No open cycles. Try showing closed cycles.';
    }
    return 'Select a cycle to manage data';
};
export const getLang = shortName => {
    if (shortName === 'es') {
        return 'Spanish';
    }
    if (shortName === 'en') {
        return 'English';
    }
    return 'Unknown';
};

export const formatDateCell = date => {
    if (date) {
        return moment(date).format('MM-DD-YYYY hh:mm A');
    }
    return date;
};

const mapStateToProps = (state, props) => ({
    cycles: state.app.data.cycles,
    site: state.app.data.site,
});
export default connect(mapStateToProps, { setCycle })(TabView);
